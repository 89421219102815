import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { history } from 'containers/App/App';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './AccessToken';

const cache = new InMemoryCache({});
const { NODE_ENV, REACT_APP_LOCAL_DB } = process.env;

let siteNodeUrl;
console.log('PROCESS REACT_APP_LOCAL_DB:', REACT_APP_LOCAL_DB);
console.log('NODE_ENV:', NODE_ENV);

switch (REACT_APP_LOCAL_DB) {
  case 'local':
    siteNodeUrl = 'http://localhost:4500/site-graphql';
    break;
  case 'report':
    siteNodeUrl = 'http://localhost:4500/site-graphql';
    break;
  case 'server':
    siteNodeUrl = 'https://sitex-dev-api.ifca.io/site-graphql';
    break;
  case 'azure':
    siteNodeUrl = 'https://sitex-dev-api.ifca.io/site-graphql';
    break;
  case 'staging':
    siteNodeUrl = 'https://sitex-api.ifca.io/site-graphql';
    break;
  case 'uat':
    siteNodeUrl = 'https://site-uat-api.contractx.asia/site-graphql';
    break;
  case 'demo':
    siteNodeUrl = 'https://site-demo-api.contractx.asia/site-graphql';
    break;
  default:
    siteNodeUrl = 'https://site-api.contractx.asia/site-graphql';
    break;
}

// NODE_ENV === 'development' ? 'http://localhost:4500/refresh_token_site' : ''

export const siteNodeRefreshUrl =
  REACT_APP_LOCAL_DB === 'report'
    ? 'http://localhost:4500/refresh_token_site'
    : REACT_APP_LOCAL_DB === 'local'
    ? 'http://localhost:4500/refresh_token_site'
    : REACT_APP_LOCAL_DB === 'server'
    ? 'https://sitex-dev-api.ifca.io/refresh_token_site'
    : REACT_APP_LOCAL_DB === 'azure'
    ? 'https://sitex-dev-api.ifca.io/refresh_token_site'
    : REACT_APP_LOCAL_DB === 'staging'
    ? 'https://sitex-api.ifca.io/refresh_token_site'
    : REACT_APP_LOCAL_DB === 'uat'
    ? 'https://site-uat-api.contractx.asia/refresh_token_site'
    : REACT_APP_LOCAL_DB === 'demo'
    ? 'https://site-demo-api.contractx.asia/refresh_token_site'
    : 'https://site-api.contractx.asia/refresh_token_site';

// NODE_ENV === 'development' ? 'http://localhost:61094' : ''

export const contractReportUrl =
  REACT_APP_LOCAL_DB === 'report'
    ? 'http://localhost:61094'
    : REACT_APP_LOCAL_DB === 'local'
    ? 'https://contractxdevreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'server'
    ? 'https://contractxdevreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'azure'
    ? 'https://contractxdevreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'staging'
    ? 'https://contractxuatreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'uat'
    ? 'https://contractxuatreporting.ifca.asia'
    : REACT_APP_LOCAL_DB === 'demo'
    ? 'https://contractxdemoreporting.contractx.asia'
    : 'https://contractxreporting.contractx.asia';

// Getting access Token and passing it in request headers
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: { authorization: `bearer ${accessToken}` },
            });
          } //accessToken is defined
        }) //then operation ends here
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }); //handle ends here
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const uploadLink = createUploadLink({
  uri: siteNodeUrl,
  credentials: 'include',
});

const httplink = new HttpLink({
  uri: siteNodeUrl,
  credentials: 'include',
}); //new HttpLink ends

export const ContractClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        // the state of token will always be available since we set it as a state

        if (!token) return true;

        try {
          const { exp }: any = jwtDecode(token);
          if (Date.now() >= exp * 1000) return false;
          else return true;
        } catch (err) {
          return false;
        }
      },
      fetchAccessToken: () => {
        return fetch(siteNodeRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        });
      },
      handleFetch: accessToken => {
        sessionStorage.setItem('accessToken', accessToken);
        setAccessToken(accessToken);
      },

      handleResponse: (operation, accessTokenField) => async response => {
        const token = await response?.json();
        if (!token?.ok) return { accessToken: '' };
        return { accessToken: token?.accessToken };
      },
      handleError: err => {
        sessionStorage.removeItem('accessToken');
        setAccessToken('');
        history.push('/');
      },
    }),
    onError(() => {}),
    requestLink,
    uploadLink,
    httplink,
  ]),
  cache,
});
